<template>
  <div>
    <WeCard>
      <div class="row align-items-end">
        <div class="col-12 col-md mb-3 mb-md-0">
          <label for="start-time" class="custom-label">Başlangıç Saati</label>
          <input
            type="time"
            v-model="item.start_time"
            id="start-time"
            class="form-control"
            ref="startTime"
          />
        </div>
        <div class="col-12 col-md mb-3 mb-md-0">
          <label for="due-time" class="custom-label">Bitiş Saati</label>
          <input
            type="time"
            v-model="item.due_time"
            id="due-time"
            class="form-control"
            ref="dueTime"
          />
        </div>
        <div class="col-12 mb-3 col-md mb-md-0">
          <WeInput
            class="mb-0"
            label="Miktar"
            v-on:keyup-enter="addNew"
            v-bind:filter-number="true"
            v-model="item.quantity"
          />
        </div>
        <div class="col-auto">
          <span class="btn btn-primary" v-on:click="addNew"
            ><i v-bind:class="actionButton.icon"></i>
            {{ actionButton.text }}</span
          >
        </div>
      </div>
      <template v-if="list && list.length">
        <hr />
        <ul class="list-group custom-scrollbar" v-bind:style="getStyle">
          <li class="list-group-item" v-for="(time, i) in list" v-bind:key="i">
            <div class="row align-items-center">
              <div class="col text-center">
                <small><i>Başlangıç Saati</i></small>
                <div class="clearfix"></div>
                <span class="font-weight-bold">
                  {{ time.start_time }}
                </span>
              </div>
              <div class="col text-center">
                <small><i>Bitiş Saati</i></small>
                <div class="clearfix"></div>
                <span class="font-weight-bold">
                  {{ time.due_time }}
                </span>
              </div>
              <div class="col text-center">
                <small><i>Miktar</i></small>
                <div class="clearfix"></div>
                <span class="font-weight-bold">
                  {{ time.quantity }}
                </span>
              </div>
              <div class="col-auto">
                <span
                  class="btn btn-outline-primary btn-sm"
                  v-on:click="onEdit(i)"
                  ><i class="fas fa-edit"></i
                ></span>
              </div>
              <div class="col-auto">
                <span
                  class="btn btn-outline-danger btn-sm"
                  v-on:click="onRemove(i)"
                  ><i class="fas fa-trash"></i
                ></span>
              </div>
            </div>
          </li>
        </ul>
      </template>
    </WeCard>
    <div class="text-center mt-4" v-show="showSubmit">
      <WeSubmitButton
        v-bind:submit-status="submit"
        v-on:submit="submitForm"
        ref="submitButton"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "WeDeliveryTime",
  data() {
    return {
      ready: false,
      submit: false,
      timeEdit: false,
      index: null,
      list: this.data,
      item: {
        start_time: null,
        due_time: null,
        quantity: 0,
      },
    };
  },
  props: {
    data: {
      default: () => [],
    },
    showSubmit: {
      default: true,
    },
    maxHeight: {
      default: 0,
    },
    cloneData: {
      default: false,
    },
  },
  model: {
    prop: "data",
  },
  methods: {
    addNew() {
      if (this.item.start_time && this.item.due_time && this.item.quantity) {
        let copy = helper.clone(this.item);
        if (!this.timeEdit) {
          this.list.push(copy);
          this.$refs.startTime.focus();
        } else {
          this.list[this.index] = copy;
          this.timeEdit = false;
        }
        helper.clearItem(this.item);
      }
    },
    onRemove(index) {
      this.list.splice(index, 1);
    },
    onEdit(index) {
      const listItem = this.list[index];
      this.index = index;
      this.item = helper.clone(listItem);
      this.timeEdit = true;
    },
    submitForm() {
      this.submit = true;
      const data = {
        list: this.list,
        loading: this.submit,
      };

      this.$emit("on-submit", data);
    },
    showAlert(result) {
      if (result && result.data && result.data.status == "success") {
        this.$toast.success("Kayıt İşlemi Başarılı !");
      } else {
        this.$toast.success("Kayıt İşlemi Hatalı !");
      }
    },
  },
  computed: {
    actionButton() {
      const result = {
        icon: "fas fa-plus",
        text: "Ekle",
      };
      if (this.timeEdit) {
        result.icon = "fas fa-edit";
        result.text = "Güncelle";
      }
      return result;
    },
    getStyle() {
      const result = {};

      if (this.maxHeight) {
        result.maxHeight = `${this.maxHeight}px`;
        result.overflow = "auto";
      }

      return result;
    },
  },
  // mounted() {
  //   this.list = this.cloneData ? helper.clone(this.data) : this.data;
  // },
};
</script>